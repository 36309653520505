import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadSchool } from "../redux/reducers/schoolReducers";

const useSchool = ({ id, accessToken }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accessToken) return; // Avoid dispatching the action if there is no access token

    dispatch(loadSchool({ id, accessToken }));
  }, [id, accessToken, dispatch]);
};

export default useSchool;
