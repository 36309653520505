import React, { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { RpfGlobalNav } from "@raspberrypifoundation/rpf-global-nav/dist";
import "../../assets/stylesheets/GlobalNav.scss";

import { useNavigate } from "react-router-dom";
import { SettingsContext } from "../../utils/settings";

const GlobalNav = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const settings = useContext(SettingsContext);

  const onLogIn = () => {
    localStorage.setItem("location", window.location.pathname);
    navigate("/auth/user_login/full");
  };

  const onLogOut = async () => {
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    await auth.removeUser();
    localStorage.clear();
  };

  const onSignUp = () => {
    window.location.href = `${process.env.REACT_APP_IDENTITY_URL}/signup`;
  };

  return (
    <RpfGlobalNav
      loggedIn={!!auth.user}
      onLogIn={onLogIn}
      onLogOut={onLogOut}
      onSignUp={onSignUp}
      theme={settings.theme}
    />
  );
};

export default GlobalNav;
