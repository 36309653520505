import { configureStore } from "@reduxjs/toolkit";
import EditorReducer from "./redux/EditorSlice";
import InstructionsReducer from "./redux/InstructionsSlice";
import SchoolReducer from "./redux/SchoolSlice";
import ClassReducer from "./redux/ClassSlice";
import LessonReducer from "./redux/LessonSlice";

const store = configureStore({
  reducer: {
    editor: EditorReducer,
    instructions: InstructionsReducer,
    school: SchoolReducer,
    class: ClassReducer,
    lesson: LessonReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          "redux-oidc/USER_FOUND",
          "redux-odic/SILENT_RENEW_ERROR",
        ],
      },
    }),
});

export default store;
