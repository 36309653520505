import React, { useMemo } from "react";

// eslint-disable-next-line
import Symbol_observable from "symbol-observable";

import "react-toastify/dist/ReactToastify.css";
import "./assets/stylesheets/App.scss";
import "./assets/stylesheets/Notifications.scss";

import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "react-oidc-context";

import AppRoutes from "./components/AppRoutes";
import GlobalNav from "./components/GlobalNav/GlobalNav";
import BetaBanner from "./components/BetaBanner/BetaBanner";
import BetaModal from "./components/Modals/BetaModal";
import LoginToSaveModal from "./components/Modals/LoginToSaveModal";
import ToastCloseButton from "./utils/ToastCloseButton";
import SettingsProvider from "./utils/SettingsProvider";
import SecondaryNav from "./components/SecondaryNav/SecondaryNav";
import CustomApolloProvider from "./utils/CustomApolloProvider";
import oidcConfig from "./utils/oidcConfig";
import Footer from "./components/Footer/Footer";

const App = () => {
  const isEmbedded = useSelector((state) => state.editor.isEmbedded);
  const userType = useSelector((state) => state.editor.userType);
  const schoolCode = useSelector((state) => state.school.code);

  const StudentAuthProvider = ({ children }) => {
    const authProviderProps = useMemo(() => {
      return oidcConfig("student", schoolCode);
    }, []);

    return (
      <AuthProvider {...authProviderProps}>
        <div data-testid="student-auth-provider">{children}</div>
      </AuthProvider>
    );
  };

  const FullAuthProvider = ({ children }) => {
    const authProviderProps = useMemo(() => {
      return oidcConfig();
    }, []);

    return (
      <AuthProvider {...authProviderProps}>
        <div data-testid="full-auth-provider">{children}</div>
      </AuthProvider>
    );
  };

  const UserAuthProvider =
    userType === "student" ? StudentAuthProvider : FullAuthProvider;

  if (!UserAuthProvider) return null;

  return (
    <UserAuthProvider userType={userType}>
      <CustomApolloProvider>
        <BrowserRouter>
          <SettingsProvider id="app">
            <ToastContainer
              enableMultiContainer
              containerId="top-center"
              position="top-center"
              className="toast--top-center"
              closeButton={ToastCloseButton}
            />
            {!isEmbedded && userType !== "student" && (
              <>
                <GlobalNav />
                <SecondaryNav />
                <BetaBanner />
              </>
            )}
            <AppRoutes />
            <Footer />
            <BetaModal />
            <LoginToSaveModal />
            <ToastContainer
              enableMultiContainer
              containerId="bottom-center"
              position="bottom-center"
              className="toast--bottom-center"
            />
          </SettingsProvider>
        </BrowserRouter>
      </CustomApolloProvider>
    </UserAuthProvider>
  );
};

export default App;
