import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import "material-symbols";
import "../../assets/stylesheets/SecondaryNav.scss";
import classNames from "classnames";
import { useMediaQuery } from "react-responsive";
import { MOBILE_MEDIA_QUERY } from "../../utils/mediaQueryBreakpoints";
import DesignSystemButton from "../DesignSystemButton/DesignSystemButton";
import FocusTrap from "focus-trap-react";
import {
  getUserRoles,
  isSchoolTeacherOrOwner,
} from "../../utils/userRoleHelper";
import useSchool from "../../hooks/useSchool";

const SecondaryNav = () => {
  const auth = useAuth();
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const location = useLocation();
  const currentPath = location.pathname.replace(/\/+$/, "");
  const isMobile = useMediaQuery({ query: MOBILE_MEDIA_QUERY });
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const school = useSelector((state) => state.school);
  const userRoles = getUserRoles(school);
  useSchool({ accessToken: auth.user?.access_token });

  // Only show the secondary nav if the route matches /{locale} or /{locale}/education
  const showSecondaryNav = location.pathname.match(
    /^\/[a-z]{2}(-[A-Z]{2})?(\/education)?\/?$/,
  );

  const toggleMenu = () => setMenuIsOpen(!menuIsOpen);
  const closeMenu = () => setMenuIsOpen(false);

  const homePath = `/${locale}`;
  const educationPath = `/${locale}/education`;

  const homeClasses = classNames("secondary-nav__link", {
    "secondary-nav__link--active": currentPath === homePath,
  });
  const educationClasses = classNames("secondary-nav__link", {
    "secondary-nav__link--active": currentPath === educationPath,
  });

  const linksClasses = classNames("secondary-nav__links", {
    "secondary-nav__links--mobile": isMobile,
  });

  useEffect(() => {
    const appElement = document.querySelector("#app");

    if (isMobile && menuIsOpen && appElement) {
      appElement.style.overflow = "hidden";
    } else if (appElement) {
      appElement.style.overflow = "auto";
    }
  }, [isMobile, menuIsOpen]);

  //  If the escape key is pressed close the menu
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setMenuIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  if (!showSecondaryNav) {
    return;
  }

  return (
    <div className="secondary-nav">
      <span className="secondary-nav__title">
        {t("secondaryNav.codeEditor")}
      </span>
      <FocusTrap active={isMobile && menuIsOpen}>
        <div className={isMobile ? "secondary-nav__links-wrapper--mobile" : ""}>
          {isMobile && (
            <DesignSystemButton
              className="secondary-nav__menu-button"
              type="tertiary"
              icon={menuIsOpen ? "close" : "menu"}
              onClick={toggleMenu}
            />
          )}
          {(!isMobile || menuIsOpen) && (
            <div className={linksClasses}>
              <Link
                className={homeClasses}
                to={`/${locale}`}
                onClick={closeMenu}
              >
                <span className="secondary-nav__link-text">
                  {t("secondaryNav.home")}
                </span>
              </Link>
              <Link
                className={educationClasses}
                to={`/${locale}/education`}
                onClick={closeMenu}
              >
                <span className="secondary-nav__link-text">
                  {t("secondaryNav.education")}
                </span>
              </Link>
              {auth.user && (
                <div className="secondary-nav__authed-links">
                  <Link
                    className="secondary-nav__link"
                    to={`/${locale}/projects`}
                    onClick={closeMenu}
                  >
                    <span className="secondary-nav__link-text">
                      {t("secondaryNav.projects")}
                    </span>
                  </Link>

                  {process.env.REACT_APP_CEFE_FEATURE &&
                    isSchoolTeacherOrOwner(userRoles) && (
                      <Link
                        className="secondary-nav__link"
                        to={`/${locale}/school`}
                        onClick={closeMenu}
                      >
                        <span className="secondary-nav__link-text">
                          {t("secondaryNav.school")}
                        </span>
                      </Link>
                    )}
                </div>
              )}
            </div>
          )}
        </div>
      </FocusTrap>
    </div>
  );
};

export default SecondaryNav;
