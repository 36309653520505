import axios from "axios";
import omit from "lodash/omit";

const host = process.env.REACT_APP_API_ENDPOINT;

const get = async (url, headers) => {
  return await axios.get(url, headers);
};

const post = async (url, body, headers) => {
  return await axios.post(url, body, headers);
};

const put = async (url, body, headers) => {
  return await axios.put(url, body, headers);
};

const headers = (accessToken) => {
  let headersHash;
  if (accessToken) {
    headersHash = { Accept: "application/json", Authorization: accessToken };
  } else {
    headersHash = { Accept: "application/json" };
  }
  return { headers: headersHash };
};

export const createOrUpdateProject = async (projectWithUserId, accessToken) => {
  const project = omit(projectWithUserId, ["user_id"]);
  if (!project.identifier) {
    return await post(
      `${host}/api/projects`,
      { project },
      headers(accessToken),
    );
  } else {
    return await put(
      `${host}/api/projects/${project.identifier}`,
      { project },
      headers(accessToken),
    );
  }
};

export const deleteProject = async (identifier, accessToken) => {
  return await axios.delete(
    `${host}/api/projects/${identifier}`,
    headers(accessToken),
  );
};

export const getImage = async (url) => {
  return await get(url, headers());
};

export const loadRemix = async (projectIdentifier, accessToken) => {
  return await get(
    `${host}/api/projects/${projectIdentifier}/remix`,
    headers(accessToken),
  );
};

export const getRemixes = async (projectIdentifier, accessToken) => {
  return await get(
    `${host}/api/projects/${projectIdentifier}/remixes`,
    headers(accessToken),
  );
};

export const createRemix = async (project, accessToken) => {
  return await post(
    `${host}/api/projects/${project.identifier}/remix`,
    { project },
    headers(accessToken),
  );
};

export const readProject = async (projectIdentifier, locale, accessToken) => {
  const queryString = locale ? `?locale=${locale}` : "";
  return await get(
    `${host}/api/projects/${projectIdentifier}${queryString}`,
    headers(accessToken),
  );
};

export const readProjectList = async (page, accessToken) => {
  return await get(`${host}/api/projects`, {
    params: { page },
    ...headers(accessToken),
  });
};

export const uploadImages = async (projectIdentifier, accessToken, images) => {
  var formData = new FormData();

  images.forEach((image) => {
    formData.append("images[]", image, image.name);
  });

  return await post(
    `${host}/api/projects/${projectIdentifier}/images`,
    formData,
    { ...headers(accessToken), "Content-Type": "multipart/form-data" },
  );
};

export const createError = async (
  projectIdentifier,
  userId,
  error,
  sendError = false,
) => {
  if (!sendError) {
    return;
  }
  const { errorMessage, errorType } = error;
  return await post(`${host}/api/project_errors`, {
    error: errorMessage,
    error_type: errorType,
    project_id: projectIdentifier,
    user_id: userId,
  });
};

export const createSchool = async (school, accessToken) => {
  return await post(`${host}/api/schools`, { school }, headers(accessToken));
};

export const getSchool = async (schoolId, accessToken) => {
  const response = await get(
    `${host}/api/schools/${schoolId}`,
    headers(accessToken),
  );
  return response.data;
};

export const getUserSchool = async (accessToken) => {
  let response;
  try {
    response = await get(`${host}/api/school`, headers(accessToken));
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) {
      return;
    } else {
      throw error;
    }
  }
};

export const getSchoolMembers = async (schoolId, accessToken) => {
  return await get(
    `${host}/api/schools/${schoolId}/students`,
    headers(accessToken),
  );
};

export const inviteTeacherToSchool = async (
  schoolId,
  teacherEmail,
  accessToken,
) => {
  return await post(
    `${host}/api/schools/${schoolId}/teachers`,
    { email_address: teacherEmail },
    headers(accessToken),
  );
};

export const createStudent = async (student, schoolId, accessToken) => {
  let response;
  try {
    response = await post(
      `${host}/api/schools/${schoolId}/students`,
      { school_student: student },
      headers(accessToken),
    );
    return response.data;
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const createClass = async (schoolClass, schoolId, accessToken) => {
  try {
    return await post(
      `${host}/api/schools/${schoolId}/classes`,
      { school_class: schoolClass },
      headers(accessToken),
    );
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const getClasses = async (schoolId, accessToken) => {
  return await get(
    `${host}/api/schools/${schoolId}/classes`,
    headers(accessToken),
  );
};

export const getClass = async (classId, schoolId, accessToken) => {
  return await get(
    `${host}/api/schools/${schoolId}/classes/${classId}`,
    headers(accessToken),
  );
};

export const addClassMember = async (
  studentId,
  classId,
  schoolId,
  accessToken,
) => {
  return await post(
    `${host}/api/schools/${schoolId}/classes/${classId}/members`,
    { class_member: { student_id: studentId } },
    headers(accessToken),
  );
};

export const getClassMembers = async (classId, schoolId, accessToken) => {
  return await get(
    `${host}/api/schools/${schoolId}/classes/${classId}/members`,
    headers(accessToken),
  );
};

export const createLesson = async (lesson, accessToken) => {
  try {
    return await post(`${host}/api/lessons`, { lesson }, headers(accessToken));
  } catch (error) {
    const errorMessage = error?.response?.data?.error;
    throw errorMessage ? new Error(errorMessage) : error;
  }
};

export const getLessons = async (classId, accessToken) => {
  return await get(
    `${host}/api/lessons?school_class_id=${classId}`,
    headers(accessToken),
  );
};

export const getLesson = async (lessonId, accessToken) => {
  const response = await get(
    `${host}/api/lessons/${lessonId}`,
    headers(accessToken),
  );
  return response;
};

export const updateLesson = async (lesson, accessToken) => {
  return await put(
    `${host}/api/lessons/${lesson.id}`,
    { lesson },
    headers(accessToken),
  );
};

export const getInvitation = async (tokenId, accessToken) => {
  return await get(
    `${host}/api/teacher_invitations/${tokenId}`,
    headers(accessToken),
  );
};

export const acceptInvitation = async (tokenId, accessToken) => {
  return await put(
    `${host}/api/teacher_invitations/${tokenId}/accept`,
    {},
    headers(accessToken),
  );
};
