import { createSlice } from "@reduxjs/toolkit";
import { reducers, extraReducers } from "./reducers/schoolReducers";

export const SchoolSlice = createSlice({
  name: "school",
  initialState: { loading: "idle" },
  reducers,
  extraReducers,
});

export const { setSchoolCode } = SchoolSlice.actions;

export default SchoolSlice.reducer;
