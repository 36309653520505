export const login = ({
  auth,
  project,
  location,
  triggerSave,
  accessDeniedData,
  loginRedirect,
} = {}) => {
  if (window.plausible) {
    window.plausible("Login button");
  }

  if (accessDeniedData) {
    localStorage.setItem(
      "location",
      `/projects/${accessDeniedData.identifier}`,
    );
  } else {
    const currentRedirect = localStorage.getItem("location");
    const fallbackRedirect = location.pathname.includes("/auth/user_login")
      ? "/"
      : location.pathname;
    localStorage.setItem(
      "location",
      loginRedirect || currentRedirect || fallbackRedirect,
    );
    if (project) {
      localStorage.setItem(
        project.identifier || "project",
        JSON.stringify(project),
      );
    }
  }
  if (triggerSave) {
    localStorage.setItem("awaitingSave", "true");
  }
  auth.signinRedirect();
};
